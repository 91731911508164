import React from "react"
import { Link } from "gatsby"

export default function Carousel() {

    return (
    <div>
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
        <ol className="carousel-indicators">
            <li data-bs-target="#myCarousel" data-bs-slide-to="0" className="active"></li>
            <li data-bs-target="#myCarousel" data-bs-slide-to="1"></li>
            <li data-bs-target="#myCarousel" data-bs-slide-to="2"></li>
        </ol>
        <div className="carousel-inner">
            <div className="carousel-item active">
            <img src="hero2.jpg" className="center img-fluid" />
            <div className="container">
                <div className="carousel-caption text-start">
                <h1>Leeds City Juniors FC.</h1>
                <p>The Premier Junior Football Club in North Leeds.</p>
                <p><Link className="btn btn-lg btn-primary" to="/history" role="button">About Us</Link></p>
                </div>
            </div>
            </div>
            <div className="carousel-item">
            <img src="hero5.jpg" className="center img-fluid" />
            <div className="container">
                <div className="carousel-caption">
                <h1>Grassroots Football.</h1>
                <p>Leeds City Juniors provides boys and girls football for teams from age 7 to 17 with players progressing annually from the juniors to the senior teams..</p>
                <p><Link className="btn btn-lg btn-primary" to="/todo">Learn more</Link></p>
                </div>
            </div>
            </div>
            <div className="carousel-item">
            <img src="hero3.jpg" className="center img-fluid" />
            <div className="container">
                <div className="carousel-caption text-end">
                <h1>Join the team.</h1>
                <p>If you are interested in joining us, we are always ready to welcome new players, coaches or assistants.</p>
                <p><Link className="btn btn-lg btn-primary" to="/contact" role="link">Contact Us</Link></p>
                </div>
            </div>
            </div>
        </div>
        <a className="carousel-control-prev" href="#myCarousel" role="button" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </a>
        <a className="carousel-control-next" href="#myCarousel" role="button" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </a>
        </div>
    </div>
    );
}
