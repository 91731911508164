import React from "react"
import { Link } from "gatsby"

export default function SponsorsCarousel(props) {

    return (
        <div className="carousel-sponsors">
        <div id="carouselSponsors" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="sponsor-kipmcgrath.jpg" className="center" alt="KipMcgrath"/>
            </div>
            <div className="carousel-item">
              <img src="sponsor-olianas.jpg" className="center" alt="Olianas"/>
            </div>
            <div className="carousel-item">
              <img src="sponsor-icongroup.jpg" className="center" alt="Icon Group"/>
            </div>
            <div className="carousel-item">
              <img src="sponsor-mstransportation.jpg" className="center" alt="MS Transportation"/>
            </div>
            <div className="carousel-item">
              <img src="sponsor-durkans.jpg" className="center" alt="Durkans & Sons Ltd"/>
            </div>
            <div className="carousel-item">
              <img src="sponsor-premiervision.jpg" className="center" alt="Premier Vision"/>
            </div>
            <div className="carousel-item">
              <img src="sponsor-copiercompany.png" className="center" alt="The Copier Company"/>
            </div>
            <div className="carousel-item">
              <img src="sponsor-gma.jpg" className="center" alt="GMA Technical Recruitment Ltd"/>
            </div>
            <div className="carousel-item">
              <img src="sponsor-mn-property-solutions.jpg" className="center" alt="MN Property Solutions"/>
            </div>
            <div className="carousel-item">
              <img src="sponsor-els.jpg" className="center" alt="ELS"/>
            </div>
            <div className="carousel-item">
              <img src="sponsor-sportsbulksuppliers.png" className="center" alt="Sports Bulk Suppliers"/>
            </div>
            <div className="carousel-item">
              <img src="sponsor-drakesplumbingheating.png" className="center" alt="Drakes Plumbing & Heating Services"/>
            </div>
            <div className="carousel-item">
              <img src="sponsor-slipstreamdesign.jpg" className="center" alt="Slipstream Engineering Design"/>
            </div>
          </div>
          <a className="carousel-control-prev" href="#carouselSponsors" role="button" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselSponsors" role="button" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
);
}
