import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Carousel from "../components/carousel"
import ImageCard from "../components/image-card"
import InnerPageTwitterLayout from "../components/inner-page-twitter-layout"
import LogoCard from "../components/logo-card"
import SponsorsCarousel from "../components/sponsors-carousel"

export default function Home() {
  return ( 
    <Layout>
      <Carousel></Carousel>

      <section className="container">

      <InnerPageTwitterLayout>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
          <div className="col">
            <ImageCard href="/history" image="lcj-action1.jpg" imageClass="object-fit-cover">Learn more about Leeds City Junior FC, the premier junior football club in North Leeds.</ImageCard>
          </div>
          <div className="col">
            <ImageCard href="/contact" image="lcj-action3.jpg" imageClass="object-fit-cover">If you are interested in joining us, we are always ready to welcome new players, coaches or assistants.</ImageCard>
          </div>
          <div className="col">
            <ImageCard href="/sponsors" image="sponsors-wanted-small.jpg" imageClass="object-fit-cover">If you are interested sponsoring a team please contact us.</ImageCard>
          </div>
        </div>

        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 mt-4">
          <div className="col">
            <LogoCard category="LCJ News" download={true} href="/LCJ Season Review 23_24.pdf" title="2023-2024 Season Review">Read the 2023-2024 season review</LogoCard>
          </div>
          <div className="col">
            <LogoCard category="LCJ News" download={true} href="/Newsletter Term 3.pdf" title="Newsletter Term 3">Welcome to our 3rd newsletter of the season</LogoCard>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col">
              <div className="card text-center">
                    <div className="card-header">
                      Our Current Sponsors
                    </div>
                    <div className="card-body">
                      <SponsorsCarousel></SponsorsCarousel>
                    </div>
                    <div className="card-footer">
                      <Link to="/sponsors">Want to sponsor a team?</Link>
                    </div>
              </div>
            </div>
        </div>
      </InnerPageTwitterLayout>

      </section>

    </Layout>
  );
}

